import { TextStyles } from 'fitify-ui'
import { ButtonStyles } from 'fitify-ui-landing/src/components/Button/Button.Styles'
import styled from 'styled-components'

export const StyledErrorContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 80px;
`
export const StyledErrorTitleWrapper = styled.div`
  border: 2px solid black;
  border-radius: 24px;
  max-width: 258px;
  min-height: 121px;
  text-align: center;

  ${TextStyles} {
    padding: 16px 21px;
  }
`
export const StyledErrorContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  > ${TextStyles} {
    margin-top: 40px;
    max-width: 480px;
    text-align: center;
  }
`
export const StyledErrorButton = styled.div`
  ${ButtonStyles} {
    margin-top: 40px;
    min-width: 258px;
    min-height: 56px;

    ${TextStyles} {
      font-size: 20px;
      line-height: 24px;
    }
  }
`
