import { Text } from 'fitify-ui'
import { useRouter } from 'next/router'
import { useTranslation } from 'next-i18next'

import Button from '../../Button/Button'
import Container from '../../Container/Container'
import Section from '../../Section/Section'

import {
  StyledErrorButton,
  StyledErrorContainer,
  StyledErrorContent,
  StyledErrorTitleWrapper,
} from './ErrorStyled'

const ErrorPage = ({
  errorTitle,
  errorDescription,
  errorParagraph,
  errorCta,
}: {
  errorTitle: string
  errorDescription: string
  errorParagraph: string
  errorCta: string
}) => {
  const router = useRouter()
  const { t } = useTranslation()

  const onButtonClick = () => {
    void router.push({ pathname: '/' })
  }

  return (
    <Section>
      <Container>
        <StyledErrorContainer>
          <StyledErrorTitleWrapper>
            <Text as={'h1'} variant={'error-title'}>
              {t(errorTitle)}
            </Text>
          </StyledErrorTitleWrapper>
          <StyledErrorContent>
            <Text as={'h3'} variant={'error-description'}>
              {t(errorDescription)}
            </Text>
            <Text as={'p'} variant={'error-paragraph'}>
              {t(errorParagraph)}
            </Text>
            <StyledErrorButton>
              <Button onClick={onButtonClick} variant={'black'}>
                {t(errorCta)}
              </Button>
            </StyledErrorButton>
          </StyledErrorContent>
        </StyledErrorContainer>
      </Container>
    </Section>
  )
}

export default ErrorPage
